/* variables */
:root{
    --dark-color: #151515;
    --green-color: #5fad05;
    --dark-green-color: #559b04;
    --new-green-color: #273;
    --shaded-green-color: #3b413c;
    --red-color: #e60012;
    --trans: all 0.3s ease-in-out;
}

*{
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: 0;
    text-decoration: none;
}

html{
    font-size: 10px; /* 1rem becomes equal to 10px */
    scroll-behavior: smooth;
    scroll-padding-top: var(--scroll-padding, 100px);
}
body{
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
    font-family: 'Work Sans', sans-serif;
    background-color: var(--shaded-green-color);
    color: #fff;
    overscroll-behavior: contain;
    scrollbar-width: thin !important;
}
body::-webkit-scrollbar {
    width: 1vw;
}
body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff51;
}
ul{
    list-style: none;
}
img{
    width: 100%;
    display: block;
}
i {
    cursor: pointer;
}

.exit-page {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 3rem;
    color: var(--new-green-color); 
}

.container{
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 2rem;
}
.flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.hide {
    display: none;
}

.navbar{
    background-color: var(--new-green-color);
    color: #fff;
    text-align: center;
    padding: 2rem 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    border-bottom: 5px solid white;
    max-height: 150px;
}
.game-nav-display {
    margin: 1.5%;
}
.game-search-bar {
    background-color: white;
    border-radius: 25px;
    margin: 0 auto;
    width: 60%;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #C33764 !important;
}

#search-icon {
    font-size: 1.4rem;
    margin-left: 1rem;
    color: #000;
}

#form-yt--search input {
    padding: 2%;
    border: none;
    outline: none;
    border-radius: 25px;
    width: 85%;
}

#form-yt--search input::placeholder {
    font-size: 1.6rem;
}

.navbar .container{
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.navbar-brand{
    font-family: 'Work Sans', sans-serif;
    font-size: 4rem;
    color: #fff;
}
.navbar-brand span{
    color: var(--green-color);
}
.search-form{
    display: none;
}
.navbar-toggler-open, .navbar-toggler-close{
    font-size: 2.8rem;
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.navbar-toggler-open:hover, .navbar-toggler-close:hover{
    opacity: 0.8;
}

.navbar-collapse{
    position: fixed;
    right: -345px;
    top: 0;
    width: 345px;
    background-color: var(--new-green-color);
    height: 100%;
    padding: 2rem 2rem 2rem 3rem;
    z-index: 999;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.navbar-toggler-close{
    position: absolute;
    right: 2rem;
    top: 2rem;
}
.navbar-nav{
    margin-top: 5rem;
}
.nav-item, .nav-item-home, .nav-item-news{
    margin: 2.4rem 0;
    text-align: left;
    font-family: 'Work Sans', sans-serif;
}
.nav-link{
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.nav-link:hover, .nav-item-dropdown:hover{
    color: var(--dark-color);
}
.nav-item-dropdown{
    margin-left: 0.5rem;
    cursor: pointer;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.nav-item-dropdown-content{
    max-height: 0;
    overflow: hidden;
}
.nav-item-dropdown-content li{
    margin-bottom: 1rem;
}
.nav-item-dropdown-content li a{
    color: #fff;
    opacity: 0.8;
    letter-spacing: 1px;
    font-weight: 300;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.nav-item-dropdown-content li a:hover{
    color: blue;
    opacity: 1;
}

/* nav item dropdown show class */
.show-dropdown{
    min-width: 14vw;
    max-height: 1000px;
    margin: 1rem 0;
}

.close-dropdown{
    display: none;
}

/* navbar show class */
.show-navbar{
    right: 0;
}

/* header section */
#header{
    min-height: 100vh;
    /* margin-top: 85px; */
    padding: 2rem 0;
}
.header-item{
    position: relative;
    height: 420px;
}
.header-item img{
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.header-item-text{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    color: #fff;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), color-stop(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0)));
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 100%);
    padding: 2.6rem;
}
h1, h2, h3, h4{
    margin: 1.6rem 0;
    line-height: 1.3;
    letter-spacing: 1px;
}

.main-h1 {
    font-size: 3.2rem;
}
.span-minimise {
    font-size: 1.8rem;
}
.text{
    margin: 1.6rem 0;
    line-height: 1.5;
    font-weight: 300;
    opacity: 0.9;
    letter-spacing: 1px;
}
.header-item-text small{
    display: block;
    margin: 1rem 0;
    font-size: 1.5rem;
    opacity: 0.6;
    font-style: italic;
}
.header-item-text a{
    background-color: var(--green-color);
    color: #fff;
    padding: 1rem 2rem;
    display: inline-block;
    margin-top: 1rem;
    border-radius: .3rem;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.header-item-text a:hover{
    background-color: var(--dark-green-color);
}

/* news */
.news-top {
    margin-top: 85px;
}
.title{
    background-color: var(--red-color);
    padding: 1rem 0;
    color: #fff;
}
.title h2{
    margin: 0;
}

.fas .fa-rss {
    margin-right: 1%;
}

.news-latest {
    display: flex;
    overflow-x: scroll;
}

.news-latest::-webkit-scrollbar {
    width: 1vw;
}
.news-latest::-webkit-scrollbar-thumb {
    background-color: #1a1a1a51;
}

.article-function {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
}

article{
    margin: 2rem 2rem 2rem 0;
    min-width: 100%;
}
.item-img{
    position: relative;
    height: 380px;
    overflow: hidden;
    width: 40%;
}
.item-img img{
    -o-object-fit: cover;
       object-fit: cover;
    height: 300px;
}
.item-img .article-top-img {
    height: 300px;
    object-fit: fill;
}
.item-img span{
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    padding: 1rem 1.5rem;
    background-color: var(--red-color);
    color: #fff;
}
.item-text{
    padding: 0 2rem;
    width: 60%;
}
.h1{
    font-size: 2.2rem;
    color: #fff;
    display: block;
    margin-bottom: 1.6rem;
    line-height: 1.3;
    letter-spacing: 1px;
}
.item-text a{
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
.item-text a:hover{
    opacity: 0.8;
}
.category{
    font-family: 'Work Sans', sans-serif;
    font-size: 1.6rem;
    display: inline-block;
    text-transform: uppercase;
    padding: 0.4rem 0.8rem;
    background-color: var(--green-color);
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: .2rem;
}
.item-text .news-notes-link{
    display: flex;
    width: 100%;
    padding: 5% 0;
    overflow-x: scroll;
    opacity: 0.7;
    letter-spacing: 1px; 
    overscroll-behavior: contain;
    scrollbar-width: thin !important;
}
.item-text .news-notes-link::-webkit-scrollbar {
    width: 1vw;
}
.item-text .news-notes-link::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ffffff51;
}

.item-text .news-notes-link a{
    border: 2px solid white;
    border-radius: 0.2rem;
    margin-right: 10px;
    padding: 1%;
    font-size: 16px;
    min-width: 22rem;
    text-align: center;
    color: white;
} 

.item-text .news-notes-link a:not(:last-of-type)::after {
    content: "/";
    margin: 0 .5rem;
    color: #ff3c00;
}

#apex-legends-mobile, #t3-arena-mobile {
    color: white;
}

.release-date {
    color: #ea0a0a;
}

/* promo */
#promo-top {
    background-color: #00214d;
    min-height: 100vh;
    margin-bottom: 2rem;
    width: 100%;
    border-top: 2px solid var(--new-green-color);
    border-bottom: 2px solid var(--new-green-color);
    padding: 6rem 0;
}

#promo{
    min-height: 80vh;
    margin-bottom: 2rem;
    text-align: center;
}

.promo-2 {
    min-height: 0 !important;
}

#promo span{
    text-transform: uppercase;
    font-family: 'Work Sans', sans-serif;
    font-size: 3rem;
}
.promo-discord a i{
    margin-top: 5%;
    font-size: 50px;
    color: var(--dark-green-color);
}
.promo-discord a i:hover{
    color: #45ad05f0;
}
#promo h2{
    font-size: 4rem;
    letter-spacing: 2px;
    font-family: 'Work Sans', sans-serif;
    margin-top: 2rem;
}
#promo .text{
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.8rem;
}
#promo .btn-groups, .btn-groups-2{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 2rem;
}
#promo .btn-groups-2{
    margin-top: 1rem;
}
#promo .btn-groups a{
    margin: 1rem;
    background-color: var(--dark-green-color);
    border-radius: .3rem;
    color: #fff;
    padding: 1.4rem 2rem;
    font-size: 1.8rem;
    text-transform: uppercase;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
#promo .btn-groups-2 a{
    margin: 1rem;
    background-color: #fff;
    box-shadow: 1px 0 10px white;
    border-radius: 2rem;
    color: var(--dark-color);
    padding: 1.4rem 2rem;
    font-size: 1.8rem;
    text-transform: uppercase;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
#promo .btn-groups-2-alt a{
    background-color: transparent !important;
    color: white !important;
}
#promo .btn-groups a:hover{
    background-color: #0084e6;
}

#promo .btn-groups-2 a:hover{
    background-color: var(--dark-color);
    color: white;
    border: 1px solid white;
    transform: scale(.96);
}
/* footer */
#footer{
    background-color: var(--dark-color);
    padding: 4rem 0;
    text-align: center;
    margin-top: 15px;
}
#footer a{
    color: #fff;
    -webkit-transition: var(--trans);
    -o-transition: var(--trans);
    transition: var(--trans);
}
#footer a:hover{
    color: #5fad05;
}
.footer-links-1 ul, .footer-links-2 ul{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.footer-links-1 {
    margin: 1.5rem 0;
}
.footer-links-1 h2{
    font-family: 'Work Sans', sans-serif;
}
.footer-links-1 ul li{
    margin: 1rem;
}
.footer-links-1 ul li a{
    font-size: 2.4rem;
}
.footer-links-2{
    margin-bottom: 2rem;
}
.footer-links-2 ul li{
    padding: 0 1rem;
    margin: 1rem 0;
    border-right: 1px solid #fff;
}
#footer .text{
    font-size: 1.3rem;
}

/* Contact Section */
.gamelounge-contact-container {
    max-width: 40%;
    margin: 0 auto;
    position: relative; 
}
.gamelounge-contact-container #gamelounge-contact {
    border-radius: 25px;
    background: #2b2b2b;
    padding: 25px;
    margin: 150px 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); 
}
.gamelounge-contact-container #gamelounge-contact .form-exit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 3rem;
    color: #C33764; 
}
.gamelounge-contact-container #gamelounge-contact h3 {
    display: block;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 10px;
    color: white; 
}
.gamelounge-contact-container #gamelounge-contact .form-text-h4 {
    margin: 5px 0 15px; 
}
.gamelounge-contact-container #gamelounge-contact .form-text-h4 h4 {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: wheat; 
}
.gamelounge-contact-container #gamelounge-contact .form-text-h4 #last-h4 i {
    color: #C33764;
    font-size: 1.4rem;
    margin-right: 1%; 
}
.gamelounge-contact-container #gamelounge-contact fieldset {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%; 
}
.gamelounge-contact-container #gamelounge-contact fieldset textarea {
    height: 20rem; 
}
.gamelounge-contact-container #gamelounge-contact input[type="text"],
.gamelounge-contact-container #gamelounge-contact input[type="email"],
.gamelounge-contact-container #gamelounge-contact textarea {
    width: 100%;
    border: 1px solid #ccc;
    background: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    transition: all .2s; 
}
.gamelounge-contact-container #gamelounge-contact input[type="text"]:hover,
.gamelounge-contact-container #gamelounge-contact input[type="email"]:hover,
.gamelounge-contact-container #gamelounge-contact textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #aaa; 
}
.gamelounge-contact-container #gamelounge-contact button[type="submit"] {
    cursor: pointer;
    width: 100%;
    border: none;
    background: #4CAF50;
    color: #FFF;
    margin: 0 0 5px;
    padding: 10px;
    font-size: 15px;
    transition: all .2s; 
}
.gamelounge-contact-container #gamelounge-contact button[type="submit"]:hover {
    background: #43A047;
    -webkit-transition: background-color 0.3s ease-in-out;
    -moz-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out; 
}
.gamelounge-contact-container ::-webkit-input-placeholder {
    color: #888; 
}
.gamelounge-contact-container :-moz-placeholder {
    color: #888; 
}
.gamelounge-contact-container ::-moz-placeholder {
    color: #888; 
}
.gamelounge-contact-container :-ms-input-placeholder {
    color: #888; 
}

/* Disclaimer & Privacy Policy */
.fair-use-disclaimer--container {
    position: relative;
    max-width: 90%;
    margin: 10% auto;
    box-shadow: 1rem 2rem 50rem black; 
}
.fair-use-disclaimer--container .disclaimer {
    padding: 2%;
    background-color: #2b2b2b; 
}
.fair-use-disclaimer--container .disclaimer .disclaimer-exit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 3rem;
    color: #C33764; 
}
.fair-use-disclaimer--container .disclaimer #fair-use-disclaimer h2, .fair-use-disclaimer--container .disclaimer #fair-use-disclaimer p {
    color: white; 
}
.fair-use-disclaimer--container .disclaimer #fair-use-disclaimer h2 {
    font-size: 2.4rem;
    margin-bottom: 2%; 
}
.fair-use-disclaimer--container .disclaimer #fair-use-disclaimer p {
    font-size: 1.6rem; 
}

.sitemap-pages {
    padding: 2rem;
}

.sitemap-pages h3 {
    background-color: #15151543;
}

.sitemap-links {
    color: white;
}
.sitemap-links:not(:last-of-type)::after {
    content: "/";
    margin: 0 .5rem;
    color: var(--new-green-color); 
}
.sitemap-content-snippet {
    background-color: #00214d;
    margin: 2rem 0;
    padding: 2rem;
    border-radius: 1rem;
}
/* media queries */

@media(min-width: 768px){
    /* news */
    #news article{
        display: flex;
    }
    #news article .item-img{
        height: 260px;
    }
}

@media(min-width: 992px){
    .navbar-toggler-close, .navbar-toggler-open{
        display: none;
    }
    .navbar-collapse{
        width: 100%;
        right: 0;
        position: relative;
        background-color: transparent;
        padding: 0;
    }
    .navbar-nav{
        margin-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
    }
    .nav-item, .nav-item-home, .nav-item-news{
        position: relative;
        margin: 0 1%;
    }
    .nav-item-dropdown-content{
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 9999999;
        background-color: var(--new-green-color);
        border-radius: .3rem;
    }
    .nav-item-dropdown-content li a{
        opacity: 1;
    }
    .nav-item-dropdown-content li a:hover{
        opacity: 0.8;
    }
    /* nav item dropdown show class */
    .show-dropdown{
        padding: 1.5rem;
    }
    .nav-link:hover, .nav-item-dropdown:hover{
        color: #fff;
        opacity: 0.8;
    }

    /* header */
    .header-content{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 2rem;
    }
    .header-item{
        height: 50vh;
    }

    .header-item:nth-child(1){
        grid-column: 1 / 5;
        grid-row: 1 / 2;
    }
    .header-item:nth-child(2){
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
    .header-item:nth-child(3){
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}

@media(min-width: 1200px){
    /* header */
    .header-item:nth-child(1){
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        height: 100%;
    }
    .header-item:nth-child(2){
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    .header-item:nth-child(3){
        grid-column: 3 / 5;
        grid-row: 2 / 3;
    }
}


/* transition and animation stopper while resizing window */
.resize-animation-stopper *{
    -webkit-animation: none!important;
            animation: none!important;
    -webkit-transition: none!important;
    -o-transition: none!important;
    transition: none!important;
}

@media(max-width: 991px){
    .navbar-collapse {
        overflow-y: scroll;
    }
}

@media(max-width: 767px){
    .item-img{
        width: 100%;
    }
    .item-text{
        padding: 2rem 0;
        width: 100%;
    }
}

@media(max-width: 560px){
    .gamelounge-contact-container {
        max-width: 80% !important; 
    }
    .mobile-text-hide{
        display: none;
    }
}

@media(max-width: 400px){
    .header-item-text .text{
        display: none;
    }
}

@media(max-width: 320px){
    .gamelounge-contact-container #gamelounge-contact fieldset textarea {
        height: 15rem !important; 
    }
}
